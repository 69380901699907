<template>
  <el-autocomplete
    v-model="state"
    :fetch-suggestions="querySearchAsync"
    placeholder="输入搜索"
    @select="handleSelect"
    style="position: fixed; top: 15%; left: 25.8%"
    popper-class="my-autocomplete"
    clearable="true"
    v-if="false"
  />
</template>
<script lang="js">
import {  defineComponent   } from "vue";
import { onMounted, ref } from "vue";
import { useRouter } from 'vue-router'
import * as passApi from "../../api/passApi.js";

export default defineComponent({
  name: "Search",
  components:{},
  setup() {
    const state = ref("");
    const links = ref([]);
    const router = useRouter();
    const loadAll = () => {
        const allPoi=JSON.parse(window.localStorage.getItem("allPoiList"));
        const data=[]
        for(let res of allPoi){
            data.push({value:res.textContent,item:res})
        }
        return data;
    };
// let timeout;
    const querySearchAsync = (queryString, cb) => {
    const results = queryString
        ? links.value.filter(createFilter(queryString))
        : links.value;

    //   clearTimeout(timeout);
    //   timeout = setTimeout(() => {
        cb(results);
    //   }, 1000);
    };
    const createFilter = (queryString) => {
    return (restaurant) => {
        return (
        restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
    };
    };
    const handleSelect = (item) => {
        passApi.locationSceneObject({objectId:item.item.id})
        //显示
        passApi.displayObject({ids:[item.item.id],display:true})
        item.item.bindID&&item.item.bindID!=''&&passApi.displayObject({ids:[item.item.bindID],display:true})
    };
    onMounted(() => {
        const text=router.currentRoute.value.query.text;
        console.log(text)
        links.value=loadAll();
    });
      return { state,querySearchAsync,handleSelect};
    },
});
</script>
<style>
.my-autocomplete li {
  line-height: normal;
  padding: 7px;
}
.el-input__wrapper {
  background-color: rgb(255, 255, 255, 0.8);
}
.el-input__inner::-webkit-input-placeholder {
  /*WebKit browsers*/
  color: rgb(94, 94, 94);
}
.el-input__inner::-moz-input-placeholder {
  /*Mozilla Firefox*/
  color: rgb(94, 94, 94);
}
.el-input__inner::-ms-input-placeholder {
  /*Internet Explorer*/
  color: rgb(94, 94, 94);
}
.my-autocomplete.is-light {
  background: rgb(255, 255, 255, 0.7);
}
.my-autocomplete.is-light .el-popper__arrow::before {
  background: rgb(255, 255, 255, 0.7);
}
.el-autocomplete-suggestion li:hover {
  background-color: rgb(255, 255, 255, 0.9);
}
</style>
